footer {
	.info {
		flex-direction: column;
		@media (min-width: 550px) {
			flex-direction: row; }
		.sec {
			margin-top: 1em;
			@media (min-width: 550px) {
				margin-top: 0; }
			h4 {
				font-size: 1.5em; } } } }
