.hero {
	height: 20em;
	background: url('/assets/imgs/header.jpg');
	background-attachment: fixed;
	img {
		width: 16em;
		@media (min-width: 550px) {
			width: 35em; } }
	h1 {
		text-transform: uppercase;
		font-size: 2em;
		font-weight: 300; } }
.boxconainer {
	flex-wrap: wrap;
	flex-direction: column;
	@media (min-width: 550px) {
		flex-direction: row;
		.box {
			width: 49.2%;
			div {
				opacity: 0; } } }
	.box {
		height: 20em;
		margin-bottom: 1em;
		&:hover {
			div {
				opacity: 1; } }
		div {
			text-align: center;
			flex-direction: column;
			background-color: rgba(#000, .8); }
		a {
			border-radius: 30px;
			padding: .6em 2em; }
		h3 {
			font-size: 2em; } } }
.possisec {
	@media (min-width: 550px) {
		max-width: 60%; } }
