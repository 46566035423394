.contactus {
	border-radius: 10px;
	@media (min-width:550px) {
		flex-direction: row; }
	flex-direction: column;
	padding: 1em;
	p {
		color: #444; }
	form {
		display: flex;
		flex-direction: column;

		input, textarea {
			padding: .5em 1em;
			resize: vertical;
			margin-bottom: 1em;
			border-radius: 5px;
			border: 1px solid lightgray; }
		button {
			padding: .5em 1em;
			border-radius: 3px;
			text-transform: uppercase; } }
	.soccial {
		flex-wrap: wrap;
		align-content: center;
		a {
			margin-right: 1em; } } }
.formsec {
	flex-direction: column;
	@media (min-width:550px) {
		width: 43%; } }
